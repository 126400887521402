<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          v-if="permissionList.addBtn"
          @click="showEdit('add')"
          >新 增
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          v-if="permissionList.editBtn"
          @click="showEdit('edit', scope)"
          >编 辑
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          v-if="permissionList.editBtn"
          @click="rowDel('edit', scope.row)"
          >删 除
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :title="isAdd? '新增': '编辑'"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
      :visible.sync="authDialog"
      width="700px"
      >
      <el-form
        :model="projectauthForm"
        ref="projectauthForm"
        inline
        size="small"
        :rules="rlues"
        label-width="80px">
        <el-form-item 
          label="飞行项目"
          prop="projectId">
          <select-tree
            :disabled="!isAdd"
            :data="projectList"
            @change="projectChange"
            v-model="projectauthForm.projectId"/>
        </el-form-item>
        <el-form-item 
          label="行业类型"
          prop="industryType">
          <el-select
            :disabled="true"
            v-model="projectauthForm.industryType"
            placeholder="请选择">
            <el-option
              v-for="item in industryTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item 
          label="巡检项目"
          prop="inspectionProjectIds">
          <select-tree
            :multiple="true"
            :data="inspectionProjectList"
            v-model="projectauthForm.inspectionProjectIds"/>
        </el-form-item>
        <div></div>
        <el-form-item 
          label="备注">
          <el-input style="width: 480px" type="textarea" v-model="projectauthForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button 
          @click="handleClose">
          取 消
        </el-button>
        <el-button 
          :loading="saveLoading"
          :disabled="inspetionProjectsLoading"
          type="primary"
          @click="submit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  getProjectAuthList,
  getDetail,
  add,
  update,
  remove,
  selectList,
  inspetionProjects
} from "@/api/project/projectauth";

import { mapGetters } from "vuex";
import { getDictionary } from '@/api/system/dictbiz.js'

export default {
  name: 'projectAuth',
  data() {
    return {
      form: {
        industryType: '',
        projectId: '',
        inspectionProjectIds: [],
        remark: ''
      },
      projectauthForm: {
        industryType: '',
        projectId: '',
        inspectionProjectIds: [],
        remark: ''
      },
      rlues: {
        projectId: [
          { required: true, message: '输入不能为空', trigger: 'blur' },
        ],
        industryType: [
          { required: true, message: '输入不能为空', trigger: 'blur' },
        ],
        inspectionProjectIds: [
          { required: true, message: '输入不能为空', trigger: 'blur' },
        ]
      },
      projectList: [],
      industryTypeList: [],
      inspectionProjectList: [],
      query: {},
      isAdd: true,
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        searchBtnText: '搜索',
        searchBtnIcon: 'el-icon-search',
        emptyBtnText: '清空',
        emptyBtnIcon: 'el-icon-delete',
        height:'auto',
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        dialogClickModal: false,
        column: [
          {
            search: true,
            label: "飞行项目",
            prop: "projectName"
          },
          {
            search: true,
            label: "行业类型",
            prop: "industryTypeStr"
          },
          {
            label: "巡检项目",
            prop: "projectNames",
            search: true
          },
          {
            label: "备注",
            prop: "remark"
          }
        ]
      },
      data: [],
      authDialog: false,
      saveLoading: false,
      inspetionProjectsLoading: false
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: !!this.permission.projectauth_add,
        delBtn: !!this.permission.projectauth_delete,
        editBtn: !!this.permission.projectauth_edit,
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  mounted () {
    this.getSelectList()
    this.getIndustryTypeList()
  },
  methods: {
    showEdit(type, node) {
      if (type === 'add') {
        this.isAdd = true
      } else {
        this.isAdd = false
        let data = JSON.parse(JSON.stringify(node.row))
        let ids = data.inspectionProjectIds
        data.inspectionProjectIds = ids.split(',')
        this.$nextTick(()=>{
          this.projectauthForm = data
        })
      }
      this.authDialog = true
    },
    submit() {
      this.$refs.projectauthForm.validate((value)=>{
        if (value) {
          if (this.isAdd) {
            this.rowSave()
          } else {
            this.rowUpdate()
          }
        }
      })
    },
    rowSave() {
      this.saveLoading = true
      let form = JSON.parse(JSON.stringify(this.projectauthForm))
      form.inspectionGrassProjectVOS = form.inspectionProjectIds.map(item=>{
        let inspectionProject = this.inspectionProjectList.find(_item=>{
          return _item.value === item
        })
        let inspectionProjectName = inspectionProject? inspectionProject.label: undefined
        return {
          inspectionProjectName,
          projectName: inspectionProjectName,
          inspectionProjectId: item
        }
      })
      form.method = 'add'
      form.projectName = this.projectList.find(_item=>{
        return _item.value === form.projectId
      }).label,
      delete form.inspectionProjectIds
      add(form).then(res => {
        if (res.data.code === 200) {
          this.onLoad(this.page);
          this.handleClose()
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      }).finally(()=>{
        this.saveLoading = false
      })
    },
    rowUpdate() {
      this.saveLoading = true
      let form = JSON.parse(JSON.stringify(this.projectauthForm))
      form.inspectionGrassProjectVOS = form.inspectionProjectIds.map(item=>{
        let inspectionProject = this.inspectionProjectList.find(_item=>{
          return _item.value === item
        })
        let inspectionProjectName = inspectionProject? inspectionProject.label: undefined
        return {
          inspectionProjectName,
          projectName: inspectionProjectName,
          inspectionProjectId: item
        }
      })
      form.method = 'edit'
      form.projectName = this.projectList.find(_item=>{
        return _item.value === form.projectId
      }).label,
      delete form.inspectionProjectIds
      update(form).then(res => {
        if (res.data.code === 200) {
          this.onLoad(this.page);
          this.handleClose()
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      }).finally(()=>{
        this.saveLoading = false
      })
    },
    rowDel(action, row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.projectId).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      if (this.$refs.crud) {
          this.$refs.crud.toggleSelection();
      }
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getProjectAuthList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
    getSelectList() {
      selectList().then(res=>{
        if (res.data.code === 200) {
          let data =  res.data.data &&  Array.isArray( res.data.data) ? res.data.data: []
          this.projectList = data.map(item=>{
            return {
              industryType: item.industryType,
              value: item.projectId,
              label: item.projectName
            }
          })
        }
      })
    },
    getIndustryTypeList() {
      getDictionary({
        code: 'BusinessType'
      }).then(res=>{
        if (res.data.code === 200) {
          this.industryTypeList = res.data.data.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          })
        }
      })
    },
    projectChange(value) {
      if (value) {
        const node = this.projectList.find(item=>{
          return item.value === value
        })
        this.projectauthForm.industryType = node.industryType
        if (this.isAdd) {
          this.projectauthForm.inspectionProjectIds = []
        }
        this.inspectionProjectList = []
        this.getInspetionProjects(node.industryType)
      }
    },
    getInspetionProjects(type) {
      this.inspetionProjectsLoading = true
      inspetionProjects({
        industryType: type
      }).then(res => {
        if (res.data.code === 200) {
          const data = res.data.data
          this.inspectionProjectList = data && Array.isArray(data)? data.map(item=>{
            return {
              label: item.projectName,
              value: item.projectId
            }
          }): []
        }
      }).finally(()=>{
        this.inspetionProjectsLoading = false
      })
    },
    handleClose() {
      this.inspectionProjectList = []
      this.$refs.projectauthForm.resetFields()
      this.authDialog = false
    }
  },
};
</script>

<style>
::v-deep .el-card__body{
  height: 100% ;
}
</style>
